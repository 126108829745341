<template >
  <div style="padding-bottom:30px;">

    <el-form label-width="92px">
      <el-row :gutter="10">
        <el-col :span="5">
          <el-form-item label="试卷ID：">
            {{list.edu_paper_id}}
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="试卷名称：">
            {{list.paper_name}}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="试卷科目：">
            {{list.subject_name}}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="试卷地区：">
            {{list.dis_name}}
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="试卷年份：">
            {{list.str_post?list.str_post.questionYear:''}}
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="试卷来源：">
            {{list.source_name}}
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="试卷总分：">
            {{list.paper_score}}
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <PaperBtn :name="list.paper_name"
              :isCollect="list.is_collect" />

    <div v-if="list.note"
         style="margin:14px">备注：{{list.note}}</div>
    <BASETOPICCOMMON ref="BASETOPICCOMMONREF"
                     :list="list.questions" />
    <Dialog ref="DialogRef" />

    <sortDialog ref="sortDialogRef" />
  <div class="btn">
      <el-button type="primary"
                 v-if="list.paper_enable == 1 && list.could_cancel_submit == 1"
                 @click="cancelSubmitAll">取消提交</el-button>
      <el-button type="primary"
                 v-if="list.paper_enable != 1"
                 @click="submitAll">提交试卷</el-button>
    </div>

    <scrollTo ref="scrollToRef"
              :list="childredList" />
  </div>
</template>

<script>
import tipicBody from '@/components/topic/index.vue'
import Dialog from '@/components/changeTopic/index.vue'
import sortDialog from '../../../../components/changeTopic/sort.vue'
import scrollTo from '@/components/scrollTo'
import PaperBtn from '@/components/paperBtn'
import BASETOPICCOMMON from '@/components/BASE_PAPER_COMMON/index.vue'

export default {
  data () {
    return {
      record_edu_paper: {},
      list: [],
      isActive: 0,
      could_change_serial: false,
      childredList: 0,
    }
  },
  components: {
    tipicBody, Dialog, sortDialog, scrollTo, BASETOPICCOMMON, PaperBtn
  },
  mounted () {
    document.getElementsByClassName('view_warp')[0].style.background = '#F6F6F6'
    this.getInfo()
  },
  methods: {
    getInfo () {
      this.$http({
        url: '/api/v1/combination/czb_paper_detail',
        method: 'get',
        params: {
          edu_paper_id: this.$route.query.edu_paper_id
        }
      }).then(res => {
        // this.record_edu_paper = res.data.record_edu_paper
        this.list = res.data
        // this.could_change_serial = res.data.could_change_serial
        setTimeout(() => {
          var oFixed = document.getElementsByClassName('yuan')
          this.childredList = oFixed.length
        }, 1000);
      })
    },
    check (item) {
      let form = {
        rule_id: item.rule_id,
        edu_paper_id: this.record_edu_paper.edu_paper_id,
        serial_in_paper: item.serial_in_paper,
        question_id: item.question_id,
        number_per_page: 1
      }
      this.$refs.DialogRef.form = form
      this.$refs.DialogRef.dialogVisible = true
    },
    cancelSubmitAll () {
      this.$http({
        url: '/api/v1/combination/cancel_test_paper_submit',
        method: 'post',
        data: {
          edu_paper_id: this.$route.query.edu_paper_id
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '取消提交试卷成功!',
          type: 'success'
        });
        this.$router.go(-1)
      })

    },
    submitAll () {
      this.$http({
        url: '/api/v1/combination/test_paper_submit',
        method: 'post',
        data: {
          edu_paper_id: this.$route.query.edu_paper_id
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '提交试卷成功!',
          type: 'success'
        });
        this.$router.push({ path: '/testPapercomposition/autonomously' })
      })
    },
    sort (item) {
      let form = JSON.parse(JSON.stringify(item))
      form.serial_in_paper = item.answer_id
      // form.new_serial = item.serial_in_paper
      this.$refs.sortDialogRef.form = form
      this.$refs.sortDialogRef.sort()
      this.$refs.sortDialogRef.dialogVisible = true
    },
  }
}
</script>

<style lang="scss" scoped>
.sort {
  cursor: pointer;
  margin-right: 10px;
  color: #409eff;
}
.check {
  cursor: pointer;
  color: #409eff;
}
.btn {
  width: 100%;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
}
::v-deep .el-loading-mask {
  z-index: 2010 !important;
}

.tipicBody_class {
  margin-top: 20px;
  position: relaive;
  margin-right: 100px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.caozuo {
}

::v-deep .el-form-item__label {
  font-weight: bold;
}
</style>